import React, { Component } from "react";
import { Fade } from "react-awesome-reveal";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p style={{ lineHeight: "1.6" }}>{children}</p>
    ),
    [BLOCKS.HEADING_1]: (node, children) => (
      <h1 style={{ fontSize: "32px", fontWeight: "bold" }}>{children}</h1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <h2 style={{ fontSize: "28px", fontWeight: "bold" }}>{children}</h2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
    [BLOCKS.HEADING_4]: (node, children) => (
      <h4 style={{ fontSize: "20px", fontWeight: "bold" }}>{children}</h4>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <h5 style={{ fontSize: "18px", fontWeight: "bold" }}>{children}</h5>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <h6 style={{ fontSize: "16px", fontWeight: "bold" }}>{children}</h6>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul
        style={{
          paddingLeft: "40px",
          marginTop: "15px",
          marginBottom: "15px",
          listStyleType: "disc",
        }}
      >
        {children}
      </ul>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <ol
        style={{
          paddingLeft: "40px",
          marginTop: "15px",
          marginBottom: "15px",
          listStyleType: "decimal",
        }}
      >
        {children}
      </ol>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
    [INLINES.HYPERLINK]: (node, children) => (
      <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    ),
  },
};

class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      selectedProject: null,
      imageModalIsOpen: false,
      selectedImage: null,
    };
  }

  openModal = (project) => {
    this.setState({ modalIsOpen: true, selectedProject: project });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false, selectedProject: null });
  };

  openImageModal = (image) => {
    this.setState({ imageModalIsOpen: true, selectedImage: image });
  };

  closeImageModal = () => {
    this.setState({ imageModalIsOpen: false, selectedImage: null });
  };

  render() {
    if (!this.props.data) return null;

    const projects = this.props.data.map((project) => {
      const projectImage = project.image.url;

      const projectTile =
        project.url && project.linkToPageDirectly ? (
          <a href={project.url} target="_blank" rel="noopener noreferrer">
            <img src={projectImage} alt={project.title} />
            <div className="text-center">{project.title}</div>
          </a>
        ) : (
          <div
            className="cursor-pointer hover:opacity-80"
            onClick={() => this.openModal(project)}
          >
            <img src={projectImage} alt={project.title} />
            <div className="text-center">{project.title}</div>
          </div>
        );

      return (
        <div key={project.title} className="columns portfolio-item">
          <div className="item-wrap">{projectTile}</div>
        </div>
      );
    });

    const { selectedProject, selectedImage } = this.state;

    return (
      <section id="portfolio">
        <Fade cascade>
          <div className="row">
            <div className="twelve columns collapsed">
              <h1>Check out some of my latest projects.</h1>

              <div
                id="portfolio-wrapper"
                className="bgrid-quarters s-bgrid-thirds cf"
              >
                {projects}
              </div>
            </div>
          </div>
        </Fade>

        <Modal
          open={this.state.modalIsOpen}
          onClose={this.closeModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          className="flex items-center justify-center"
        >
          <Box className="w-full h-full max-w-7xl max-h-screen bg-white rounded-lg p-8 overflow-auto relative">
            {selectedProject && (
              <>
                <h2 className="text-6xl mb-8">{selectedProject.title}</h2>
                <div className="text-center">
                  <img
                    src={selectedProject.image.url}
                    alt={selectedProject.title}
                  />
                </div>
                <div className="mb-6">
                  {documentToReactComponents(
                    selectedProject.description.json,
                    options
                  )}
                </div>

                {selectedProject.additionalImagesCollection.items.map(
                  (image) => (
                    <div
                      key={image.title}
                      className="relative mb-6"
                      onClick={() => this.openImageModal(image)}
                      style={{ cursor: "pointer" }}
                    >
                      <img src={image.url} alt={image.title} />
                      <p className="mt-2 text-center">{image.description}</p>
                      <ZoomInIcon
                        className="absolute top-2 right-2 text-gray-400"
                        style={{ fontSize: 32 }}
                      />
                    </div>
                  )
                )}
                <button
                  onClick={this.closeModal}
                  className="absolute top-4 right-4 bg-gray-700 text-white rounded px-4 py-2"
                >
                  x
                </button>
              </>
            )}
          </Box>
        </Modal>

        <Modal
          open={this.state.imageModalIsOpen}
          onClose={this.closeImageModal}
          aria-labelledby="image-modal-title"
          aria-describedby="image-modal-description"
          className="flex items-center justify-center"
        >
          <Box className="w-screen-minus-16rem max-h-screen bg-white rounded-lg p-4 overflow-auto relative MuiBox-root css-0">
            {selectedImage && (
              <>
                <div className="flex justify-center items-center">
                  <img
                    src={selectedImage.url}
                    alt={selectedImage.title}
                    style={{ maxHeight: "80vh", maxWidth: "100%" }}
                  />
                </div>
                <Typography
                  id="image-modal-description"
                  className="text-center"
                  style={{ fontSize: "16px", marginTop: "4px" }}
                >
                  {selectedImage.description}
                </Typography>
                <button
                  onClick={this.closeImageModal}
                  className="absolute top-4 right-4 bg-gray-700 text-white rounded px-4 py-2"
                >
                  x
                </button>
              </>
            )}
          </Box>
        </Modal>
      </section>
    );
  }
}

export default Portfolio;
