import React, { useState, useEffect } from "react";
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import About from "./Components/About";
import Resume from "./Components/Resume";
import Contact from "./Components/Contact";
import Portfolio from "./Components/Portfolio";

const query = `{
  mainDataCollection {
    items {
      	name
      	description {
          json
        }
      	image {
          url
        }
      	stackimage{
          url
        }
      	bio {
          json
        }
      	contactmessage
      	email
      	phone
      	github
      	linkedin
      	project
      	website
      	resumedownload{
          url
        }
        address{
          ... on Address{
            city
            state
            zip
          }
        }
    }
  }
  
  resumeCollection{
    items {
      skillmessage
      educationCollection(limit:2){
        items{
          ...on Education {
            school
            degree
            graduated
            description
          }
        }
      }
      workCollection(limit:2){
        items{
          ...on Work {
            company
            title
            titleTwo
            titleThree
            years
            yearsTwo
            yearsThree
            description {
              json
            }
          }
        }
      }
      skillsCollection(limit:9){
        items{
          ...on Skills {
            name
            level
          }
        }
      }
    }
  }
 
  portfolioCollection{
    items{
      description
      projectsCollection(limit: 8){
        items{
        	... on Project {
            title
            category
            linkToPageDirectly
            description {
              json
            }
            image {
              url
            }
            additionalImagesCollection(limit:9){
              items {
                url
                description
                title
              }
            
            }
            url
            
          }
        }
      }
    }
  }
}`;

function App() {
  const [page, setPage] = useState(null);

  useEffect(() => {
    window
      .fetch(
        `https://graphql.contentful.com/content/v1/spaces/` +
          process.env.REACT_APP_CONTENTFUL_SPACE_ID,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
          },
          body: JSON.stringify({ query }),
        }
      )
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        setPage(data);
      });
  }, []);

  if (!page) {
    return "Loading...";
  }

  return (
    <div className="App">
      <Header data={page.mainDataCollection.items[0]} />
      <About data={page.mainDataCollection.items[0]} />
      <Resume data={page.resumeCollection.items[0]} />
      <Portfolio
        data={page.portfolioCollection.items[0].projectsCollection.items}
      />
      {/* <Contact data={page.mainDataCollection.items[0]} /> */}
      <Footer />
    </div>
  );
}

export default App;
