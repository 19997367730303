import React, { Component } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { Slide } from "react-awesome-reveal";

const RICHTEXT_OPTIONS = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <span>{children}</span>;
    },
    [INLINES.HYPERLINK]: (node, children) => {
      return <a href={node.data.uri}>{children}</a>;
    },
  },
};

class Resume extends Component {
  getRandomColor() {
    // Define a range of calming blue shades
    const blueShades = [
      "#ADD8E6", // LightBlue
      "#B0E0E6", // PowderBlue
      "#87CEEB", // SkyBlue
      "#87CEFA", // LightSkyBlue
      "#4682B4", // SteelBlue
      "#5F9EA0", // CadetBlue
      "#6495ED", // CornflowerBlue
      "#00BFFF", // DeepSkyBlue
      "#1E90FF", // DodgerBlue
      "#4169E1", // RoyalBlue
    ];

    // Randomly select a shade of blue
    const randomIndex = Math.floor(Math.random() * blueShades.length);
    return blueShades[randomIndex];
  }

  render() {
    if (!this.props.data) return null;

    const education = this.props.data.educationCollection.items.map(function (
      education
    ) {
      return (
        <div key={education.name}>
          <h3>{education.school}</h3>
          <p className="info">
            {education.degree} <span>&bull;</span>
            <em className="date">{education.graduated}</em>
          </p>
          <p>{education.description}</p>
        </div>
      );
    });

    const work = this.props.data.workCollection.items.map(function (work) {
      return (
        <div key={work.company}>
          <h3>{work.company}</h3>
          <p className="info">
            {work.title}
            <span>&bull;</span> <em className="date">{work.years}</em>
          </p>
          {work.titleTwo ? (
            <p className="info">
              {work.titleTwo}
              <span>&bull;</span> <em className="date">{work.yearsTwo}</em>
            </p>
          ) : (
            ""
          )}
          {work.titleThree ? (
            <p className="info">
              {work.titleThree}
              <span>&bull;</span> <em className="date">{work.yearsThree}</em>
            </p>
          ) : (
            ""
          )}
          <p>
            {documentToReactComponents(work.description.json, RICHTEXT_OPTIONS)}
          </p>
        </div>
      );
    });

    const skills = this.props.data.skillsCollection.items.map((skills) => {
      const backgroundColor = this.getRandomColor();
      const className = "bar-expand " + skills.name.toLowerCase();
      const width = skills.level;

      return (
        <li key={skills.name}>
          <span style={{ width, backgroundColor }} className={className}></span>
          <em>{skills.name}</em>
        </li>
      );
    });

    return (
      <section id="resume">
        <Slide left duration={1300}>
          <div className="row education">
            <div className="three columns header-col">
              <h1>
                <span>Education</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns">{education}</div>
              </div>
            </div>
          </div>
        </Slide>

        <Slide left duration={1300}>
          <div className="row work">
            <div className="three columns header-col">
              <h1>
                <span>Relevant Work</span>
              </h1>
            </div>

            <div className="nine columns main-col">{work}</div>
          </div>
        </Slide>

        <Slide left duration={1300}>
          <div className="row skill">
            <div className="three columns header-col">
              <h1>
                <span>Skills</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              <div className="bars">
                <ul className="skills">{skills}</ul>
              </div>
            </div>
          </div>
        </Slide>
      </section>
    );
  }
}

export default Resume;
